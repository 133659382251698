import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Blog_list from "../components/Blog/Blog_list";
const Blog = () => {
  return (
    <div>
      <Blog_list />
    </div>
  );
};

export default Blog;
