import React from "react";
import Pages_list from "../components/Pages/Pages_list";

const Pages = () => {
  return (
    <div>
      <Pages_list />
    </div>
  );
};

export default Pages;
