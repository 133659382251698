import React from "react";
import Misison_list from "../components/Mission/Mission_list";

const Misison = () => {
  return (
    <div>
      <Misison_list />
    </div>
  );
};

export default Misison;
