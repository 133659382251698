import React from "react";
import Supplier_list from "../components/Supplier/Supplier_list";

const Supplier = () => {
  return (
    <div>
      <Supplier_list />
    </div>
  );
};

export default Supplier;
