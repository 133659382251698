import React from "react";
import Support_list from "../components/Support/Support_list";

const Support = () => {
  return (
    <div>
      <Support_list />
    </div>
  );
};

export default Support;
