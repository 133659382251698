import React from "react";
import Homes from "../components/Homes/Homes";

const Home = () => {
  return (
    <div>
      <Homes />
    </div>
  );
};

export default Home;
