import React from "react";
import Vision_list from "../components/Vision/Vision_list";

const Vision = () => {
  return (
    <div>
      <Vision_list />
    </div>
  );
};

export default Vision;
