import React from "react";
import Banner_list from "../components/Banner/Banner_list";

const Banner = () => {
  return (
    <div>
      <Banner_list />
    </div>
  );
};

export default Banner;
