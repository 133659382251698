import React from "react";
import Strength_list from "../components/Strength/Strength_list";

const Strength = () => {
  return (
    <div>
      <Strength_list />
    </div>
  );
};

export default Strength;
